// src/components/HeroSection.tsx
import React, { FC, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import CTAButton from './CTAButton';

interface HeroSectionProps {
  // Removed videoSrc since we are now working with images
  images: string[]; // Array of image paths
  onJoinWaitlist: () => void;
}

// Keyframe for fade-in and fade-out effect
const fadeInOut = keyframes`
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
`;

const HeroContainer = styled.section`
  position: relative;
  height: 90vh;
  width: 100%;
  overflow: hidden;
`;

// Styling for each image in the slideshow
const SlideImage = styled.img<{ $isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 2s ease-in-out;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const HeroContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 800px;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
`;

const Headline = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Subheadline = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const FloatingCTA = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const HeroSection: FC<HeroSectionProps> = ({ images, onJoinWaitlist }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); // Loop through images
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [images.length]);

  return (
    <HeroContainer>
      {/* Image Slideshow */}
      {images.map((image, index) => (
        <SlideImage
          key={image}
          src={image}
          $isVisible={index === currentImageIndex}
        />
      ))}

      {/* Optional video background (commented out) */}
      {/* <VideoBackground autoPlay muted loop>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground> */}
      
      <Overlay />
      <HeroContent>
        <Headline>Transform Your Mealtime: Effortless, Personalized, and Stress-Free.</Headline>
        <Subheadline>
          Busy? Let Sporksy take care of meal planning and grocery shopping, so you can focus on what matters most—family and enjoying great food.
        </Subheadline>
        <CTAButton onClick={onJoinWaitlist}>Join the Waitlist and Take Control of Mealtime</CTAButton>
      </HeroContent>
      <FloatingCTA>
        <CTAButton onClick={onJoinWaitlist}>Join the Waitlist</CTAButton>
      </FloatingCTA>
    </HeroContainer>
  );
};

export default HeroSection;

// styles/SurveyContainer.tsx
import styled from 'styled-components';

export const SurveyContainer = styled.div`
  padding: 80px 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

// src/components/AdminDashboard/AdminDashboard.tsx
import React, { FC, useState, useEffect, ChangeEvent, FormEvent } from 'react';
import {
  DashboardContainer,
  Section,
  SectionTitle,
  Form,
  Label,
  Input,
  Button,
  ErrorMessage,
  SuccessMessage,
} from './AdminDashboard.styles';
import { db, auth, storage } from '../../firebase'; 
import { collection, doc, addDoc, updateDoc, getDoc, getDocs, serverTimestamp, DocumentData } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface ContentSection {
  id: number;
  content: string;
  images: string[]; // URLs of uploaded images
}

interface BlogPostData {
  id: string;
  title: string;
  subtitle: string;
  contentSections: ContentSection[];
  author: string;
  authorId: string;
  createdAt: any;
  updatedAt: any;
}

const AdminDashboard: FC = () => {
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [contentSections, setContentSections] = useState<ContentSection[]>([{ id: 1, content: '', images: [] }]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [existingPosts, setExistingPosts] = useState<BlogPostData[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editPostId, setEditPostId] = useState<string | null>(null);

  // Fetch existing blog posts
  const fetchExistingPosts = async () => {
    try {
      const blogPostsCollection = collection(db, 'blogPosts');
      const querySnapshot = await getDocs(blogPostsCollection);
      const posts: BlogPostData[] = [];
      querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        posts.push({
          id: docSnap.id,
          title: data.title,
          subtitle: data.subtitle,
          contentSections: data.contentSections,
          author: data.author,
          authorId: data.authorId,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        });
      });
      setExistingPosts(posts);
    } catch (err) {
      console.error(err);
      toast.error('Failed to fetch existing blog posts.');
    }
  };

  useEffect(() => {
    fetchExistingPosts(); // Fetch the posts when the component is mounted
  }, []);

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);
  const handleSubtitleChange = (e: ChangeEvent<HTMLInputElement>) => setSubtitle(e.target.value);
  const handleContentChange = (id: number, value: string) =>
    setContentSections((prevSections) => prevSections.map((section) => (section.id === id ? { ...section, content: value } : section)));

  const handleImageUpload = async (id: number, e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const storageRef = ref(storage, `blogImages/${Date.now()}_${file.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setContentSections((prevSections) =>
          prevSections.map((section) => (section.id === id ? { ...section, images: [...section.images, downloadURL] } : section))
        );
        toast.success('Image uploaded successfully!');
      } catch (uploadError) {
        console.error(uploadError);
        toast.error('Failed to upload image. Please try again.');
      }
    }
  };

  const addContentSection = () => setContentSections((prevSections) => [...prevSections, { id: prevSections.length + 1, content: '', images: [] }]);

  const removeContentSection = (id: number) => setContentSections((prevSections) => prevSections.filter((section) => section.id !== id));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    setSuccess('');

    if (!title.trim() || !subtitle.trim()) {
      setError('Title and Subtitle are required.');
      setIsSubmitting(false);
      return;
    }

    try {
      if (isEditing && editPostId) {
        // Update existing post
        const postRef = doc(db, 'blogPosts', editPostId);
        await updateDoc(postRef, {
          title,
          subtitle,
          contentSections,
          updatedAt: serverTimestamp(),
        });
        toast.success('Blog post updated successfully!');
        setSuccess('Blog post updated successfully!');
      } else {
        // Create new post
        const blogPostsCollection = collection(db, 'blogPosts');
        const newPost = {
          title,
          subtitle,
          contentSections,
          author: auth.currentUser?.displayName || 'Admin',
          authorId: auth.currentUser?.uid || null,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };
        await addDoc(blogPostsCollection, newPost);
        toast.success('Blog post created successfully!');
        setSuccess('Blog post created successfully!');
      }

      resetForm();
      fetchExistingPosts(); // Fetch updated posts
    } catch (err) {
      console.error(err);
      setError('Failed to submit blog post. Please try again.');
      toast.error('Failed to submit blog post. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setSubtitle('');
    setContentSections([{ id: 1, content: '', images: [] }]);
    setIsEditing(false);
    setEditPostId(null);
  };

  const handleEditPost = async (postId: string) => {
    const postRef = doc(db, 'blogPosts', postId);
    const docSnap = await getDoc(postRef);
    if (docSnap.exists()) {
      const postData = docSnap.data() as BlogPostData;
      setTitle(postData.title);
      setSubtitle(postData.subtitle);
      setContentSections(postData.contentSections);
      setEditPostId(postId);
      setIsEditing(true);
    }
  };

  const handleDeletePost = async (postId: string) => {
    if (window.confirm('Are you sure you want to delete this blog post?')) {
      try {
        const postRef = doc(db, 'blogPosts', postId);
        await updateDoc(postRef, { deleted: true }); // Soft delete
        toast.success('Blog post deleted successfully!');
        setExistingPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
      } catch (err) {
        console.error(err);
        toast.error('Failed to delete blog post.');
      }
    }
  };

  return (
    <DashboardContainer>
      <Section>
        <SectionTitle>{isEditing ? 'Edit Blog Post' : 'Create New Blog Post'}</SectionTitle>
        <Form onSubmit={handleSubmit}>
          <div>
            <Label htmlFor="title">Title</Label>
            <Input type="text" id="title" value={title} onChange={handleTitleChange} placeholder="Enter blog post title" required />
          </div>
          <div>
            <Label htmlFor="subtitle">Subtitle</Label>
            <Input type="text" id="subtitle" value={subtitle} onChange={handleSubtitleChange} placeholder="Enter blog post subtitle" required />
          </div>
          {contentSections.map((section, index) => (
            <Section key={section.id}>
              <SectionTitle>Section {index + 1}</SectionTitle>
              <ReactQuill theme="snow" value={section.content} onChange={(value) => handleContentChange(section.id, value)} placeholder="Write content here..." />
              <div>
                <Label htmlFor={`image-upload-${section.id}`}>Upload Images</Label>
                <Input type="file" id={`image-upload-${section.id}`} accept="image/*" onChange={(e) => handleImageUpload(section.id, e)} multiple />
              </div>
              {section.images.length > 0 && (
                <div>
                  <Label>Uploaded Images:</Label>
                  <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                    {section.images.map((url, idx) => (
                      <img key={idx} src={url} alt={`Section ${index + 1} Image ${idx + 1}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                    ))}
                  </div>
                </div>
              )}
              {contentSections.length > 1 && (
                <Button type="button" onClick={() => removeContentSection(section.id)}>
                  Remove Section
                </Button>
              )}
            </Section>
          ))}
          <Button type="button" onClick={addContentSection}>
            Add Another Section
          </Button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : isEditing ? 'Update Blog Post' : 'Publish Blog Post'}
          </Button>
          {isEditing && <Button onClick={resetForm}>Cancel Edit</Button>}
        </Form>
      </Section>

      <Section>
        <SectionTitle>Existing Blog Posts</SectionTitle>
        {existingPosts.length === 0 ? (
          <p>No blog posts found.</p>
        ) : (
          <ul>
            {existingPosts.map((post) => (
              <li key={post.id} style={{ marginBottom: '1rem' }}>
                <strong>{post.title}</strong> by {post.author} on {new Date(post.createdAt?.toDate()).toLocaleDateString()}
                <div>
                  <Button onClick={() => handleEditPost(post.id)}>Edit</Button>
                  <Button onClick={() => handleDeletePost(post.id)} style={{ backgroundColor: '#e63946' }}>
                    Delete
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </Section>
    </DashboardContainer>
  );
};

export default AdminDashboard;

import React from 'react';
import styled from 'styled-components';

const ProgressContainer = styled.div`
  width: 100%;
  background-color: #ddd;
  border-radius: 25px;
  margin-bottom: 2rem;
  height: 20px;
`;

const ProgressFiller = styled.div<{ $percentage: number }>`
  height: 100%;
  width: ${({ $percentage }) => $percentage}%;
  background-color: #ff6f61;
  border-radius: inherit;
  transition: width 0.5s ease-in-out;
`;

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = React.memo(({ currentStep, totalSteps }) => {
  const percentage = ((currentStep + 1) / totalSteps) * 100;
  return (
    <ProgressContainer
      role="progressbar"
      aria-valuenow={currentStep + 1}
      aria-valuemin={1}
      aria-valuemax={totalSteps}
      aria-label={`Progress: Step ${currentStep + 1} of ${totalSteps}`}
    >
      <ProgressFiller $percentage={percentage} />
    </ProgressContainer>
  );
});

export default ProgressBar;

// src/components/DemoSection.tsx
import React, { FC } from 'react';
import styled from 'styled-components';
import CTAButton from './CTAButton';
import { FaLock } from 'react-icons/fa';

interface DemoSectionProps {
  onJoinWaitlist: () => void;
}

const Section = styled.section`
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f9f9f9;
`;

const Headline = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Subheadline = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #555;
`;

const DemoImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const DemoImage = styled.img`
  width: 80%;
  max-width: 600px;
  margin-bottom: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LockIcon = styled(FaLock)`
  margin-right: 8px;
`;

const AccessNote = styled.p`
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #777;
`;

const DemoSection: FC<DemoSectionProps> = ({ onJoinWaitlist }) => {
  return (
    <Section>
      <Headline>See Sporksy in Action</Headline>
      <Subheadline>
        Want to experience Sporksy? Join the waitlist for exclusive access to our demo, available for a limited time.
      </Subheadline>
      
      {/* Container for the image to ensure proper alignment */}
      <DemoImageContainer>
        <DemoImage src="/assets/mealplanner-dashboard.png" alt="Sporksy Dashboard Preview" />
      </DemoImageContainer>

      <CTAButton onClick={onJoinWaitlist}>
        <LockIcon />
        Join the Waitlist and Unlock the Demo
      </CTAButton>

      <AccessNote>
        Access will be granted on a case-by-case basis.
      </AccessNote>
    </Section>
  );
};

export default DemoSection;

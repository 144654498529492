import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './MealPlanner.css';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';

type MealType = 'breakfast' | 'lunch' | 'dinner';

interface Meal {
  breakfast: string | null;
  lunch: string | null;
  dinner: string | null;
  [key: string]: any;
}

interface DayPlan {
  day: string;
  meals: Meal;
}

type MealPlan = DayPlan[];

const MealPlanner: React.FC = () => {
  const [diet, setDiet] = useState('');
  const [familySize, setFamilySize] = useState('');
  const [cuisine, setCuisine] = useState<string[]>([]);
  const [mealPlan, setMealPlan] = useState<MealPlan | null>(null);
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const surveyRef = useRef<HTMLDivElement>(null);
  const mealPlanRef = useRef<HTMLDivElement>(null);
  const [userEmail, setUserEmail] = useState('');
  const location = useLocation();

  const [isSelectionCollapsed, setIsSelectionCollapsed] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { diet, familySize, cuisine } = location.state as {
        diet: string;
        familySize: string;
        cuisine: string[];
      };

      setDiet(diet);
      setFamilySize(familySize);
      setCuisine(cuisine);

      // Proceed to generate the meal plan
      handleMealPlanRequest();

      // Collapse the selection section
      setIsSelectionCollapsed(true);
    }
  }, [location.state]);

  // Scroll to meal plan after generation
  useEffect(() => {
    if (mealPlan && mealPlanRef.current) {
      mealPlanRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [mealPlan]);

  const handleMealPlanRequest = async () => {
    setLoading(true);
    setError(null);
    setMealPlan(null);

    setIsSelectionCollapsed(true); // Collapse the section

    const apiUrl =
      process.env.NODE_ENV === 'production'
        ? '/generate-meal-plan'
        : 'http://localhost:5001/generate-meal-plan';

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ diet, familySize, cuisines: cuisine }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const parsedMealPlan: MealPlan = data.mealPlan;
      setMealPlan(parsedMealPlan);
    } catch (error) {
      console.error('Error generating meal plan:', error);
      setError('Failed to generate meal plan. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCuisineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCuisine = e.target.value;
    setCuisine((prev) =>
      prev.includes(selectedCuisine)
        ? prev.filter((item) => item !== selectedCuisine)
        : [...prev, selectedCuisine]
    );
  };

  // Function to delete a meal
  const deleteMeal = (dayIndex: number, mealType: MealType) => {
    setMealPlan((prevMealPlan) => {
      if (prevMealPlan) {
        const updatedMealPlan = [...prevMealPlan];
        updatedMealPlan[dayIndex].meals[mealType] = null;
        return updatedMealPlan;
      }
      return prevMealPlan;
    });
  };

  // Function to handle input change for new meals
  const handleMealInputChange = (
    dayIndex: number,
    mealType: MealType,
    value: string
  ) => {
    setMealPlan((prevMealPlan) => {
      if (prevMealPlan) {
        const updatedMealPlan = [...prevMealPlan];
        updatedMealPlan[dayIndex].meals[`${mealType}Input`] = value;
        return updatedMealPlan;
      }
      return prevMealPlan;
    });
  };

  // Function to regenerate a meal
  const regenerateMeal = async (dayIndex: number, mealType: MealType) => {
    if (!mealPlan) return;

    const dayPlan = mealPlan[dayIndex];
    const description = dayPlan.meals[`${mealType}Input`] || '';

    const apiUrl =
      process.env.NODE_ENV === 'production'
        ? '/regenerate-meal'
        : 'http://localhost:5001/regenerate-meal';

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          day: dayPlan.day,
          mealType,
          description,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const newMeal = data.newMeal.match(/"([^"]*)"/)[1];
      if (newMeal) {
        setMealPlan((prevMealPlan) => {
          if (prevMealPlan) {
            const updatedMealPlan = [...prevMealPlan];
            updatedMealPlan[dayIndex].meals[mealType] = newMeal;
            // Clear the input after regenerating
            delete updatedMealPlan[dayIndex].meals[`${mealType}Input`];
            return updatedMealPlan;
          }
          return prevMealPlan;
        });
      } else {
        console.error('Error regenerating meal:', data.error);
      }
    } catch (error) {
      console.error('Error regenerating meal:', error);
    }
  };

  const handleEmailPlan = async () => {
    const apiUrl =
      process.env.NODE_ENV === 'production'
        ? '/email-meal-plan'
        : 'http://localhost:5001/email-meal-plan';

    if (!userEmail) {
      alert('Please enter a valid email address.');
      return;
    }

    setEmailLoading(true); // Start loading indicator

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mealPlan, userEmail }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.message) {
        alert('Your meal plan has been emailed to you. Please check your inbox!');
      } else {
        console.error('Error emailing meal plan:', data.error);
        alert('There was an error sending your meal plan. Please try again.');
      }
    } catch (error) {
      console.error('Error emailing meal plan:', error);
      alert('An error occurred while sending your meal plan. Please try again.');
    } finally {
      setEmailLoading(false); // Stop loading indicator
    }
  };

  return (
    <div className="mealplanner-container" ref={surveyRef}>
      {/* App Title */}
      <header className="app-header">
        <h1>Sporksy</h1>
        <h2>Let's cook!</h2>
      </header>

      {/* Toggle Button for Collapsing/Expanding the Selection Section */}
      <div
        className="selection-toggle"
        onClick={() => setIsSelectionCollapsed(!isSelectionCollapsed)}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <span style={{ marginRight: '8px' }}>
          {isSelectionCollapsed ? 'Show Options' : 'Hide Options'}
        </span>
        {isSelectionCollapsed ? <FaChevronDown /> : <FaChevronUp />}
      </div>

      {/* Collapsible Selection Section */}
      {!isSelectionCollapsed && (
        <div className="mealplanner-selection">
          {/* Diet Preference */}
          <div className="mealplanner-step">
            <h2 className="mealplanner-title">Select Diet Preference</h2>
            <select value={diet} onChange={(e) => setDiet(e.target.value)}>
              <option value="">Select...</option>
              {[
                'Vegetarian',
                'Vegan',
                'Pescatarian',
                'Gluten-Free',
                'Paleo',
                'Keto',
                'Dairy-Free',
                'Low-Carb',
                'Low-Fat',
                'Omnivore',
                'None',
              ].map((option) => (
                <option key={option} value={option.toLowerCase()}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          {/* Family Size */}
          <div className="mealplanner-step">
            <h2 className="mealplanner-title">What is your family size?</h2>
            <select
              value={familySize}
              onChange={(e) => setFamilySize(e.target.value)}
            >
              <option value="">Select...</option>
              {[
                '1 Adult',
                '2 Adults',
                '2 Adults, 1 Child',
                '2 Adults, 2 Children',
                '2 Adults, 3 Children',
                '3 Adults',
              ].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          {/* Cuisines */}
          <div className="mealplanner-step">
            <h2 className="mealplanner-title">Choose Favorite Cuisines</h2>
            <div className="mealplanner-checkbox-group">
              {[
                'Italian',
                'Mexican',
                'Chinese',
                'Indian',
                'Middle Eastern',
                'Japanese',
                'Thai',
                'Greek',
                'African',
                'French',
                'Spanish',
                'American',
                'Korean',
                'Vietnamese',
                'Caribbean',
                'Brazilian',
                'Mediterranean',
                'Eastern European',
              ].map((type) => (
                <label key={type} className="mealplanner-checkbox-label">
                  <input
                    className="mealplanner-checkbox"
                    type="checkbox"
                    value={type}
                    checked={cuisine.includes(type)}
                    onChange={handleCuisineChange}
                  />
                  <span>{type}</span>
                </label>
              ))}
            </div>
          </div>

          {/* Generate Meal Plan Button */}
          <div className="mealplanner-buttons">
            <button
              className="next-button"
              onClick={handleMealPlanRequest}
              disabled={!diet || !familySize || loading}
            >
              {loading ? 'Generating...' : 'Generate Meal Plan'}
            </button>
          </div>
        </div>
      )}

      {/* Loading Indicator */}
      {loading && (
        <div className="mealplanner-loading">
          <div className="mealplanner-spinner"></div>
          <p>Loading your meal plan...</p>
        </div>
      )}

      {/* Error Message */}
      {error && (
        <div className="mealplanner-error">
          <p>{error}</p>
        </div>
      )}

      {/* Meal Plan Display */}
      {mealPlan && !loading && (
        <div className="mealplanner-results" ref={mealPlanRef}>
          <h2>Your Weekly Meal Plan</h2>

          {mealPlan.map((dayPlan, dayIndex) => (
            <div key={dayPlan.day} className="mealplanner-day-card">
              <h3>{dayPlan.day}</h3>
              {(['breakfast', 'lunch', 'dinner'] as MealType[]).map((mealType) => {
                const mealDescription = dayPlan.meals[mealType];

                if (mealDescription) {
                  return (
                    <div key={mealType} className="meal-item">
                      <p>
                        <strong>
                          {mealType.charAt(0).toUpperCase() + mealType.slice(1)}:
                        </strong>{' '}
                        {mealDescription}
                      </p>
                      <button
                        className="delete-button"
                        onClick={() => deleteMeal(dayIndex, mealType)}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  );
                } else {
                  return (
                    <div key={mealType} className="meal-item">
                      <p>
                        <strong>
                          {mealType.charAt(0).toUpperCase() + mealType.slice(1)}:
                        </strong>
                      </p>
                      <div className="meal-actions">
                        <input
                          type="text"
                          placeholder="Type your meal idea or leave blank"
                          value={dayPlan.meals[`${mealType}Input`] || ''}
                          onChange={(e) =>
                            handleMealInputChange(dayIndex, mealType, e.target.value)
                          }
                        />
                        <button onClick={() => regenerateMeal(dayIndex, mealType)}>
                          Create New Meal
                        </button>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ))}

          {/* Email Section */}
          <div className="email-section">
            <input
              type="email"
              placeholder="Enter your email address"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              required
            />
            <button
              className="cta-email-plan"
              onClick={handleEmailPlan}
              disabled={!userEmail || emailLoading}
            >
              {emailLoading ? 'Sending...' : 'Email Me This Plan'}
            </button>
          </div>
        </div>
      )}

      {/* Email Loading Indicator */}
      {emailLoading && (
        <div className="mealplanner-loading">
          <div className="mealplanner-spinner"></div>
          <p>Sending your meal plan via email...</p>
        </div>
      )}
    </div>
  );
};

export default MealPlanner;

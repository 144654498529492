import React from 'react';
import styled from 'styled-components';
import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';
import TextAreaField from './TextAreaField';
import TextInputField from './TextInputField';
import { SurveyResponses } from '../../types';

const QuestionContainer = styled.div`
  margin-bottom: 1.5rem;
  text-align: center;
  width: 100%;
`;

const QuestionTitle = styled.h3<{ $mandatory?: boolean }>`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #333;

  &:after {
    content: ${({ $mandatory }) => ($mandatory ? "' *'" : "''")};
    color: red;
    margin-left: 4px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
`;

// Styling for the response options container
const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

interface SurveyStepProps {
  step: number;
  responses: SurveyResponses;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errors: { [key: string]: string };
}

const SurveyStep: React.FC<SurveyStepProps> = React.memo(
  ({ step, responses, handleChange, errors }) => {
    const renderError = (fieldName: string) =>
      errors[fieldName] ? (
        <ErrorMessage id={`${fieldName}-error`} role="alert">
          {errors[fieldName]}
        </ErrorMessage>
      ) : null;

    switch (step) {
      case 0:
        return (
          <>
            <QuestionContainer>
              <QuestionTitle $mandatory>
                How do you currently plan your meals?
              </QuestionTitle>
              <OptionsContainer>
                <RadioGroup
                  name="mealPlanning"
                  options={[
                    { id: 'mealPlanning1', label: "I don’t plan ahead", value: "I don’t plan ahead" },
                    { id: 'mealPlanning2', label: 'I plan meals weekly', value: 'I plan meals weekly' },
                    { id: 'mealPlanning3', label: 'I plan meals daily', value: 'I plan meals daily' },
                    { 
                      id: 'mealPlanning4', 
                      label: 'I use an app or service for meal planning', 
                      value: 'I use an app or service for meal planning' 
                    },
                  ]}
                  selectedValue={responses.mealPlanning}
                  onChange={handleChange}
                  ariaDescribedBy={errors.mealPlanning ? 'mealPlanning-error' : undefined}
                />
              </OptionsContainer>
              {renderError('mealPlanning')}
            </QuestionContainer>

            <QuestionContainer>
              <QuestionTitle $mandatory>
                How do you typically shop for groceries?
              </QuestionTitle>
              <OptionsContainer>
                <RadioGroup
                  name="groceryShopping"
                  options={[
                    { id: 'groceryShopping1', label: 'I go to the store myself', value: 'I go to the store myself' },
                    { id: 'groceryShopping2', label: 'I use online grocery delivery', value: 'I use online grocery delivery' },
                    { 
                      id: 'groceryShopping3', 
                      label: 'I rely on a mix of in-store and online shopping', 
                      value: 'I rely on a mix of in-store and online shopping' 
                    },
                  ]}
                  selectedValue={responses.groceryShopping}
                  onChange={handleChange}
                  ariaDescribedBy={errors.groceryShopping ? 'groceryShopping-error' : undefined}
                />
              </OptionsContainer>
              {renderError('groceryShopping')}
            </QuestionContainer>
          </>
        );

      case 1:
        return (
          <>
            <QuestionContainer>
              <QuestionTitle $mandatory>
                What challenges do you face with meal planning?
              </QuestionTitle>
              <OptionsContainer>
                <CheckboxGroup
                  name="mealPlanningChallenges"
                  options={[
                    { id: 'mealPlanningChallenges1', label: 'Finding time to plan meals', value: 'Finding time to plan meals' },
                    { id: 'mealPlanningChallenges2', label: 'Sticking to a budget', value: 'Sticking to a budget' },
                    { id: 'mealPlanningChallenges3', label: 'Avoiding food waste', value: 'Avoiding food waste' },
                    { id: 'mealPlanningChallenges4', label: 'Sticking to healthy options', value: 'Sticking to healthy options' },
                    { id: 'mealPlanningChallenges5', label: 'Finding options the kids love', value: 'Finding options the kids love' },
                  ]}
                  selectedValues={responses.mealPlanningChallenges}
                  onChange={handleChange}
                  maxSelections={3}
                  ariaDescribedBy={errors.mealPlanningChallenges ? 'mealPlanningChallenges-error' : undefined}
                />
              </OptionsContainer>
              {renderError('mealPlanningChallenges')}
            </QuestionContainer>

            <QuestionContainer>
              <QuestionTitle $mandatory>
                On a scale of 1 to 10, how stressful do you find meal planning and shopping? (1 being no stress at all, 10 being extremely stressful)
              </QuestionTitle>
              <OptionsContainer>
                <RadioGroup
                  name="mealPlanningStress"
                  options={[...Array(10)].map((_, index) => ({
                    id: `mealPlanningStress${index + 1}`,
                    label: `${index + 1}`,
                    value: index + 1,
                  }))}
                  selectedValue={responses.mealPlanningStress}
                  onChange={handleChange}
                  ariaDescribedBy={errors.mealPlanningStress ? 'mealPlanningStress-error' : undefined}
                />
              </OptionsContainer>
              {renderError('mealPlanningStress')}
            </QuestionContainer>
          </>
        );

      case 2:
        return (
          <>
            <QuestionContainer>
              <QuestionTitle $mandatory>
                How much do you currently spend on groceries each week?
              </QuestionTitle>
              <OptionsContainer>
                <RadioGroup
                  name="currentGrocerySpending"
                  options={[
                    { id: 'currentGrocerySpending1', label: 'Less than $100', value: 'Less than $100' },
                    { id: 'currentGrocerySpending2', label: '$100-$200', value: '$100-$200' },
                    { id: 'currentGrocerySpending3', label: '$200-$300', value: '$200-$300' },
                    { id: 'currentGrocerySpending4', label: 'More than $300', value: 'More than $300' },
                  ]}
                  selectedValue={responses.currentGrocerySpending}
                  onChange={handleChange}
                  ariaDescribedBy={errors.currentGrocerySpending ? 'currentGrocerySpending-error' : undefined}
                />
              </OptionsContainer>
              {renderError('currentGrocerySpending')}
            </QuestionContainer>

            <QuestionContainer>
              <QuestionTitle $mandatory>
                If you could save time and reduce food waste, how much would you consider paying for an automated meal planning and grocery delivery service per month?
              </QuestionTitle>
              <OptionsContainer>
                <RadioGroup
                  name="willingToPay"
                  options={[
                    { id: 'willingToPay1', label: 'Less than $5', value: 'Less than $5' },
                    { id: 'willingToPay2', label: '$5 to $10', value: '$5 to $10' },
                    { id: 'willingToPay3', label: '$10 to $30', value: '$10 to $30' },
                    { id: 'willingToPay4', label: 'More than $30', value: 'More than $30' },
                  ]}
                  selectedValue={responses.willingToPay}
                  onChange={handleChange}
                  ariaDescribedBy={errors.willingToPay ? 'willingToPay-error' : undefined}
                />
              </OptionsContainer>
              {renderError('willingToPay')}
            </QuestionContainer>
          </>
        );

      case 3:
        return (
          <>
            <QuestionContainer>
              <QuestionTitle $mandatory>
                Which of the following features would be most valuable to you? (Select up to 3)
              </QuestionTitle>
              <OptionsContainer>
                <CheckboxGroup
                  name="valuableFeatures"
                  options={[
                    { id: 'valuableFeatures1', label: 'Personalized meal recommendations', value: 'Personalized meal recommendations' },
                    { id: 'valuableFeatures2', label: 'Automatic shopping list creation', value: 'Automatic shopping list creation' },
                    { id: 'valuableFeatures3', label: '1 Click Meal Planning & Delivery', value: '1 Click Meal Planning & Delivery' },
                    { id: 'valuableFeatures4', label: 'Automated pantry/fridge stock tracking', value: 'Automated pantry/fridge stock tracking' },
                    { id: 'valuableFeatures5', label: 'Cheapest shop possible: best deals from all supermarkets', value: 'Cheapest shop possible: best deals from all supermarkets' },
                  ]}
                  selectedValues={responses.valuableFeatures}
                  onChange={handleChange}
                  maxSelections={3}
                  ariaDescribedBy={errors.valuableFeatures ? 'valuableFeatures-error' : undefined}
                />
              </OptionsContainer>
              {renderError('valuableFeatures')}
            </QuestionContainer>
          </>
        );

      case 4:
        return (
          <>
            <QuestionContainer>
              <QuestionTitle $mandatory>
                How important is it that the meal service reduces food waste?
              </QuestionTitle>
              <OptionsContainer>
                <RadioGroup
                  name="sustainabilityImportance"
                  options={[
                    { id: 'sustainabilityImportance1', label: 'Very important', value: 'Very important' },
                    { id: 'sustainabilityImportance2', label: 'Somewhat important', value: 'Somewhat important' },
                    { id: 'sustainabilityImportance3', label: 'Neutral', value: 'Neutral' },
                  ]}
                  selectedValue={responses.sustainabilityImportance}
                  onChange={handleChange}
                  ariaDescribedBy={errors.sustainabilityImportance ? 'sustainabilityImportance-error' : undefined}
                />
              </OptionsContainer>
              {renderError('sustainabilityImportance')}
            </QuestionContainer>
          </>
        );

      case 5:
        return (
          <>
            <QuestionContainer>
              <QuestionTitle $mandatory>
                Please enter your name
              </QuestionTitle>
              <TextInputField
                name="name"
                value={responses.name}
                onChange={handleChange}
                placeholder="Your name"
                required
                ariaDescribedBy={errors.name ? 'name-error' : undefined}
              />
              {renderError('name')}
            </QuestionContainer>

            <QuestionContainer>
              <QuestionTitle $mandatory>
                Please enter your email
              </QuestionTitle>
              <TextInputField
                name="email"
                value={responses.email}
                onChange={handleChange}
                placeholder="Your email"
                required
                ariaDescribedBy={errors.email ? 'email-error' : undefined}
              />
              {renderError('email')}
            </QuestionContainer>

            <QuestionContainer>
              <QuestionTitle>
                What would make meal planning and grocery shopping easier for you?
              </QuestionTitle>
              <TextAreaField
                name="easeOfMealPlanning"
                value={responses.easeOfMealPlanning}
                onChange={handleChange}
                placeholder="Your feedback..."
                ariaDescribedBy={errors.easeOfMealPlanning ? 'easeOfMealPlanning-error' : undefined}
              />
              {errors.easeOfMealPlanning && (
                <ErrorMessage id="easeOfMealPlanning-error" role="alert">
                  {errors.easeOfMealPlanning}
                </ErrorMessage>
              )}
            </QuestionContainer>

            <QuestionContainer>
              <QuestionTitle>
                If you could change one thing about how you currently plan and prepare meals, what would it be?
              </QuestionTitle>
              <TextAreaField
                name="changeMealPlanning"
                value={responses.changeMealPlanning}
                onChange={handleChange}
                placeholder="Your feedback..."
                ariaDescribedBy={errors.changeMealPlanning ? 'changeMealPlanning-error' : undefined}
              />
              {errors.changeMealPlanning && (
                <ErrorMessage id="changeMealPlanning-error" role="alert">
                  {errors.changeMealPlanning}
                </ErrorMessage>
              )}
            </QuestionContainer>

            <QuestionContainer>
              <QuestionTitle>
                Would you like to receive updates about our meal planning service?
              </QuestionTitle>
              <OptionsContainer>
                <RadioGroup
                  name="receiveUpdates"
                  options={[
                    { id: 'receiveUpdates1', label: 'Yes', value: 'Yes' },
                    { id: 'receiveUpdates2', label: 'No', value: 'No' },
                  ]}
                  selectedValue={responses.receiveUpdates}
                  onChange={handleChange}
                  ariaDescribedBy={errors.receiveUpdates ? 'receiveUpdates-error' : undefined}
                />
              </OptionsContainer>
              {errors.receiveUpdates && (
                <ErrorMessage id="receiveUpdates-error" role="alert">
                  {errors.receiveUpdates}
                </ErrorMessage>
              )}
            </QuestionContainer>
          </>
        );

      default:
        return null;
    }
  }
);

export default SurveyStep;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import Header from './components/header/Header';
import LandingPage from './components/landingpage/LandingPage';
import MealPlanner from './components/MealPlanner';
import Account from './components/account/Account';
import Blog from './components/blog/Blog';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import Survey from './components/landingpage/Survey'; // Import Survey component
import MealPlanningQuiz from './components/quiz/MealPlanningQuiz'; // Import Quiz component
import { useAuth } from './hooks/useAuth'; 

const App: React.FC = () => {
  const { currentUser } = useAuth();

  // You can define a function that checks whether the user is an admin
  const isAdmin = currentUser?.email === 'kaifus87@gmail.com'; // Replace with proper admin-check logic

  // Define the onJoinWaitlist function to pass to the MealPlanningQuiz
  const handleJoinWaitlist = () => {
    console.log('User wants to join the waitlist');
    // You can show a modal, redirect, or perform other actions here
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStyle />
        <Header /> 
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Survey" element={<Survey />} /> {/* Added route for Survey */}
          <Route 
            path="/Quiz" 
            element={<MealPlanningQuiz onJoinWaitlist={handleJoinWaitlist} />} 
          /> {/* Added route for Quiz */}
                    <Route 
            path="/MealPlanner" 
            element={<MealPlanner/>} 
          /> {/* Added route for Quiz */}
          {isAdmin && (
            <Route path="/admin" element={<AdminDashboard />} />
          )} {/* Only render AdminDashboard route if user is an admin */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;

// src/components/Testimonials.tsx
import React, { FC } from 'react';
import styled from 'styled-components';

interface TestimonialData {
  quote: string;
  author: string;
}

const Section = styled.section`
  padding: 80px 20px;
  text-align: center;
`;

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const Testimonial = styled.div`
  max-width: 700px;
  margin: 0 auto 2rem auto;
`;

const Quote = styled.p`
  font-size: 1.1rem;
  font-style: italic;
  color: #555;
  margin-bottom: 1rem;
`;

const Author = styled.p`
  font-size: 1rem;
  font-weight: bold;
`;

const Testimonials: FC = () => {
  const testimonials: TestimonialData[] = [
    {
      quote: "Sporksy has transformed our meal planning. We spend less time in the kitchen and more time with our family!",
      author: "Jane D., Busy Professional",
    },
    {
      quote: "The automated grocery shopping feature is a game-changer. It saves us so much time every week.",
      author: "Mike S., Working Parent",
    },
    {
      quote: "Healthy, delicious meals without the hassle. Sporksy is exactly what we needed!",
      author: "Laura K., Health Enthusiast",
    },
  ];

  return (
    <Section>
      <Headline>Here’s What People Are Saying</Headline>
      {testimonials.map((testimonial, index) => (
        <Testimonial key={index}>
          <Quote>"{testimonial.quote}"</Quote>
          <Author>- {testimonial.author}</Author>
        </Testimonial>
      ))}
    </Section>
  );
};

export default Testimonials;

// src/components/Blog/Blog.styles.ts
import styled from 'styled-components';

export const BlogContainer = styled.main`
  max-width: 1000px;
  margin: 50px auto;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 20px;
    padding: 1rem;
  }
`;

export const BlogTitle = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

export const PostsList = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const PostItem = styled.li`
  background-color: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover,
  &:focus-within {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const PostTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
`;

export const PostMeta = styled.p`
  color: #666666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

export const PostExcerpt = styled.p`
  color: #333333;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const ReadMoreLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.focus};
    outline-offset: 2px;
  }
`;

export const Loader = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.text};
`;

export const ErrorMessage = styled.p`
  text-align: center;
  color: #e63946;
  font-size: 1rem;
`;

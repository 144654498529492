// src/components/Features.tsx
import React, { FC } from 'react';
import styled from 'styled-components';
import CTAButton from './CTAButton';

interface FeaturesProps {
  onJoinWaitlist: () => void;
}

const Section = styled.section`
  padding: 80px 20px;
  text-align: center;
`;

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const FeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  align-items: start;
  justify-items: center;
  margin-top: 40px;

  @media (max-width: 768px) {
    gap: 30px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`;

const Feature = styled.div`
  text-align: center;
  max-width: 300px;
`;

const FeatureImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 1rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: #555;
`;

const Features: FC<FeaturesProps> = ({ onJoinWaitlist }) => {
  return (
    <Section>
      <Headline>Your Meal Planning Made Simple</Headline>
      <FeaturesContainer>
        <Feature>
          <FeatureImage src="/assets/menu-icon.png" alt="Hyper-Personalized Meal Plans" />
          <FeatureTitle>Hyper-Personalized Meal Plans</FeatureTitle>
          <FeatureDescription>
            Using AI, Sporksy tailors meal recommendations to your family’s unique tastes, dietary preferences, and nutritional goals.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureImage src="/assets/pan-flip.png" alt="Sustainable and Waste-Free" />
          <FeatureTitle>Sustainable and Waste-Free</FeatureTitle>
          <FeatureDescription>
            Minimize food waste and reduce grocery costs by planning smarter, portion-controlled meals.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureImage src="/assets/open-sign.png" alt="Shopping Made Easy" />
          <FeatureTitle>Shopping Made Easy</FeatureTitle>
          <FeatureDescription>
            Automatically generates grocery lists or connects with your favorite delivery service—so the groceries come to you.
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureImage src="/assets/cooking-pot.png" alt="Family-Friendly Recipes" />
          <FeatureTitle>Family-Friendly Recipes</FeatureTitle>
          <FeatureDescription>
            Simple, healthy, and delicious recipes that even picky eaters will love.
          </FeatureDescription>
        </Feature>
      </FeaturesContainer>
      <CTAButton onClick={onJoinWaitlist} style={{ marginTop: '40px' }}>
        Ready to Simplify Your Meals? Join the Waitlist
      </CTAButton>
    </Section>
  );
};

export default Features;

// src/components/Blog/BlogPost.tsx
import React, { FC } from 'react';
import {
  PostItem,
  PostTitle,
  PostMeta,
  PostExcerpt,
  ReadMoreLink,
} from './Blog.styles';
import { Link } from 'react-router-dom';

interface BlogPostProps {
  id: string;
  title: string;
  author: string;
  date: string;
  excerpt: string;
}

const BlogPost: FC<BlogPostProps> = ({ id, title, author, date, excerpt }) => {
  return (
    <PostItem>
      <PostTitle>{title}</PostTitle>
      <PostMeta>
        By {author} on {new Date(date).toLocaleDateString()}
      </PostMeta>
      <PostExcerpt>{excerpt}</PostExcerpt>
      <ReadMoreLink as={Link} to={`/Blog/${id}`} aria-label={`Read more about ${title}`}>
        Read More &raquo;
      </ReadMoreLink>
    </PostItem>
  );
};

export default BlogPost;

// src/components/HowItWorks.tsx
import React, { FC } from 'react';
import styled from 'styled-components';
import CTAButton from './CTAButton';

interface HowItWorksProps {
  onJoinWaitlist: () => void;
}

const Section = styled.section`
  padding: 80px 20px;
  text-align: center;
  background-color: #f9f9f9;
`;

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Subheadline = styled.p`
  font-size: 1.1rem;
  margin-bottom: 3rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Step = styled.div`
  flex: 1 1 250px;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const StepDescription = styled.p`
  font-size: 1rem;
`;

const HowItWorks: FC<HowItWorksProps> = ({ onJoinWaitlist }) => {
  return (
    <Section>
      <Headline>How It Works</Headline>
      <Subheadline>
      Stop Guessing. Start Enjoying.
      </Subheadline>
      <StepsContainer>
        <Step>
          <StepTitle>Tailored Meals</StepTitle>
          <StepDescription>
            Receive personalized meal recommendations based on your preferences and diet.
          </StepDescription>
        </Step>
        <Step>
          <StepTitle>Automated Grocery Shopping</StepTitle>
          <StepDescription>
          We generate and even submit your grocery lists to delivery services—zero hassle.
          </StepDescription>
        </Step>
        <Step>
          <StepTitle>Easy Cooking</StepTitle>
          <StepDescription>
          Follow simple, family-friendly recipes designed to save time and reduce stress.
          </StepDescription>
        </Step>
        <Step>
          <StepTitle>Sustainability at the Core</StepTitle>
          <StepDescription>
            With Sporksy, you waste less, save money, and eat more mindfully. Better for you, better for the planet.
          </StepDescription>
        </Step>
      </StepsContainer>
      <CTAButton onClick={onJoinWaitlist}>Get on the List – Early Access Awaits</CTAButton>
    </Section>
  );
};

export default HowItWorks;

import React from 'react';
import styled from 'styled-components';

const StyledTextArea = styled.textarea<{ hasError: boolean }>`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid ${({ hasError }) => (hasError ? 'red' : '#ccc')};
  resize: vertical;

  &:focus {
    outline: 2px solid #ff6f61;
    border-color: #ff6f61;
  }
`;

interface TextAreaFieldProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  required?: boolean;
  ariaDescribedBy?: string;
}

const TextAreaField: React.FC<TextAreaFieldProps> = React.memo(
  ({ name, value, onChange, placeholder, rows = 4, required = false, ariaDescribedBy }) => (
    <StyledTextArea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      required={required}
      aria-required={required}
      aria-describedby={ariaDescribedBy}
      hasError={!!ariaDescribedBy}
    />
  )
);

export default TextAreaField;

import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { getFirestore, collection, addDoc, updateDoc, doc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../hooks/useAuth';
import CTAButton from '../landingpage/CTAButton';
import { toast } from 'react-toastify';
import { Transition, TransitionGroup, TransitionStatus } from 'react-transition-group';
import SignupModal from '../landingpage/SignupModal';
import Confetti from 'react-confetti';

const duration = 300;

// Define animations using keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const QuizContainer = styled.div`
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: #fff8f0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
`;

const QuizTitle = styled.h2`
  color: #ff6f61;
  font-size: 2.5rem;
  margin-bottom: 10px;
`;

const QuizTagline = styled.p`
  color: #333;
  margin-bottom: 30px;
  font-size: 1.2rem;
`;

const Question = styled.h3`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`;

const OptionButton = styled.button<{ selected: boolean }>`
  padding: 15px 30px;
  width: 80%;
  background-color: ${({ selected }) => (selected ? '#ff6f61' : '#f0f0f0')};
  color: ${({ selected }) => (selected ? '#fff' : '#333')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #ff6f61;
    color: #fff;
    transform: scale(1.05);
  }

  &:focus {
    outline: 2px solid #ff6f61;
  }
`;

const ProgressBarContainer = styled.div`
  background-color: #e0e0e0;
  border-radius: 50px;
  margin: 20px 0;
  height: 15px;
`;

const ProgressBarFiller = styled.div<{ $percentage: number }>`
  height: 100%;
  width: ${({ $percentage }) => $percentage}%;
  background-color: #ff6f61;
  border-radius: inherit;
  transition: width 0.5s ease-in-out;
`;

const ResultsContainer = styled.div`
  text-align: center;
`;

const ResultTitle = styled.h3`
  color: #ff6f61;
  font-size: 1.8rem;
  margin-bottom: 10px;
`;

const ResultDescription = styled.p`
  color: #555;
  margin: 20px 0;
  font-size: 1.1rem;
`;

const ShareButtons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const ShareButton = styled.button<{ $platform: string }>`
  padding: 12px 20px;
  background-color: ${({ $platform }) =>
    $platform === 'facebook' ? '#4267B2' :
    $platform === 'twitter' ? '#1DA1F2' :
    '#ccc'};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: ${({ $platform }) =>
      $platform === 'facebook' ? '#365899' :
      $platform === 'twitter' ? '#0d95e8' :
      '#b3b3b3'};
    transform: scale(1.05);
  }

  &:focus {
    outline: 2px solid #fff;
  }
`;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: Record<TransitionStatus, React.CSSProperties> = {
  entering: { opacity: 1, transform: 'translateX(0)' },
  entered: { opacity: 1, transform: 'translateX(0)' },
  exiting: { opacity: 0, transform: 'translateX(-100%)' },
  exited: { opacity: 0, transform: 'translateX(-100%)' },
  unmounted: {}, 
};

const TransitionWrapper = ({ inProp, children }: { inProp: boolean; children: React.ReactNode }) => (
  <Transition in={inProp} timeout={duration}>
    {(state: TransitionStatus) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state], 
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

interface MealPlanningQuizProps {
  onJoinWaitlist: () => void;
}

// Define the quiz questions array
const questions = [
  { id: 1, question: 'Do you ever open the fridge and realize you have no idea what to cook?' },
  { id: 2, question: 'Have you ever bought ingredients with the best intentions but ended up throwing them out?' },
  { id: 3, question: 'Do you find yourself eating out or ordering takeout more than you’d like to admit?' },
  { id: 4, question: 'Does planning meals for the week feel more like a chore than something you enjoy?' },
  { id: 5, question: 'Would you love to try new recipes, but end up cooking the same meals on repeat?' },
];

// Define the quiz result outcomes
const results = [
  {
    title: 'The Meal Prep Master',
    description: 'You have your meal planning down to an art! Discover new ways to elevate your culinary game with Sporksy.',
  },
  {
    title: 'The Last-Minute Scrambler',
    description: 'You thrive under pressure but could use some structured meal plans to save time and reduce stress. Let Sporksy help streamline your evenings.',
  },
  {
    title: 'The Takeout Temptation',
    description: 'Eating out is your go-to, but with Sporksy, you can enjoy delicious homemade meals without the hassle.',
  },
  {
    title: 'The Aspiring Chef',
    description: 'You’re eager to explore new recipes but need a little guidance and convenience. Sporksy is here to inspire and assist you.',
  },
];

// Function to save quiz results
const saveQuizResults = async (quizId: string, answers: string[], result: string, db: any) => {
  try {
    const quizDocRef = doc(db, 'quizResults', quizId);
    await updateDoc(quizDocRef, {
      answers,
      resultTitle: result,
      timestamp: serverTimestamp(),
    });
    console.log(`Quiz results saved to Firestore: ${result}`);
  } catch (error) {
    console.error('Error saving quiz results:', error);
    toast.error('Failed to save your quiz results.');
  }
};

// Function to share results on Facebook
const shareOnFacebook = (quizResult: string) => {
    console.log('Sharing on Facebook...');
    
    const url = encodeURIComponent(window.location.href); // Current page URL
    const text = encodeURIComponent(`I just discovered my Meal Planning Persona: ${quizResult}! What's yours? Find out with Sporksy!`);
    
    // Construct Facebook share URL
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`;
    
    // Open a new window to share on Facebook
    window.open(facebookUrl, '_blank');
  };
  
  // Function to share results on Twitter
  const shareOnTwitter = (quizResult: string) => {
    console.log('Sharing on Twitter...');
    
    const url = encodeURIComponent(window.location.href); // Current page URL
    const text = encodeURIComponent(`I just found out my Meal Planning Persona: ${quizResult} with Sporksy! #MealPlanning #Sporksy`);
    
    // Construct Twitter share URL
    const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
    
    // Open a new window to share on Twitter
    window.open(twitterUrl, '_blank');
  };
  
  // Function to "share" on Instagram (Instagram doesn't support direct content sharing via URL)
  const shareOnInstagram = () => {
    console.log('Sharing on Instagram...');
    
    // Instagram doesn't support pre-filled content via URL, so we can just direct users to their site
    window.open('https://www.instagram.com/', '_blank');
  };
  

const MealPlanningQuiz: React.FC<MealPlanningQuizProps> = ({ onJoinWaitlist }) => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<string[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [quizId, setQuizId] = useState<string | null>(null);
  const [confettiVisible, setConfettiVisible] = useState<boolean>(false);
  const [showCTA, setShowCTA] = useState<boolean>(false); 
  const initializingRef = useRef(false); 
  const toastDisplayed = useRef(false); 

  const totalQuestions = questions.length;
  const { currentUser } = useAuth();
  const db = getFirestore();

  useEffect(() => {
    const initializeQuiz = async () => {
      if (initializingRef.current) return;
      console.log("Initializing quiz...");
      initializingRef.current = true;

      try {
        const userId = currentUser ? currentUser.uid : 'anonymous';
        const docRef = await addDoc(collection(db, 'quizResults'), {
          userId: userId,
          answers: [],
          timestamp: serverTimestamp(),
        });
        setQuizId(docRef.id);
        console.log(`Quiz initialized with ID: ${docRef.id}`);
      } catch (error) {
        console.error('Error initializing quiz:', error);
        toast.error('Failed to start the quiz. Please try again.');
      }
    };

    initializeQuiz();
  }, [currentUser, db]);

  const handleAnswer = async (answer: string) => {
    const updatedAnswers = [...answers, answer];
    setAnswers(updatedAnswers);

    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < totalQuestions) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowResults(true);
      await saveQuizResults(quizId!, updatedAnswers, calculateResult().title, db);
      setConfettiVisible(true); 
      setTimeout(() => setShowCTA(true), 3000); 
    }

    if (quizId) {
      try {
        const quizDocRef = doc(db, 'quizResults', quizId);
        await updateDoc(quizDocRef, {
          answers: updatedAnswers,
        });
        console.log(`Answer saved to Firestore: ${updatedAnswers}`);
      } catch (error) {
        console.error('Error saving answer:', error);
        toast.error('Failed to save your answer.');
      }
    }
  };

  const calculateResult = () => {
    const yesCount = answers.filter((answer) => answer === 'Yes').length;

    if (yesCount >= 4) return results[0]; 
    if (yesCount >= 3) return results[1]; 
    if (yesCount >= 2) return results[2]; 
    return results[3]; 
  };

  const handleJoinWaitlistClick = async () => {
    setShowModal(true);
    console.log("Waitlist button clicked.");

    if (!toastDisplayed.current) {
      toastDisplayed.current = true; 
      toast.success('Waitlist signup completed. Check your email for confirmation!');
      console.log('Toast triggered for waitlist signup.');
    }
  };

  return (
    <QuizContainer>
      {confettiVisible && <Confetti recycle={false} numberOfPieces={400} />}

      <QuizTitle>What is your Meal Prep Persona?</QuizTitle>

      <TransitionGroup>
        {!showResults ? (
          <TransitionWrapper inProp={currentQuestion < totalQuestions}>
            <div>
              <QuizTagline>Discover your meal planning style and compare to your friends!</QuizTagline>
              <ProgressBarContainer>
                <ProgressBarFiller $percentage={((currentQuestion + 1) / totalQuestions) * 100} />
              </ProgressBarContainer>
              <Question>{questions[currentQuestion].question}</Question>
              <Options>
                <OptionButton selected={false} onClick={() => handleAnswer('Yes')}>Yes</OptionButton>
                <OptionButton selected={false} onClick={() => handleAnswer('No')}>No</OptionButton>
              </Options>
            </div>
          </TransitionWrapper>
        ) : (
          <TransitionWrapper inProp={showResults}>
            <ResultsContainer>
              <QuizTagline>Your Meal Prep Persona is Ready!</QuizTagline>
              <ResultTitle>{calculateResult().title}</ResultTitle>
              <ResultDescription>{calculateResult().description}</ResultDescription>
              {showCTA && (
                <>
                  <CTAButton onClick={handleJoinWaitlistClick}>Get Your Results and Join the Waitlist!</CTAButton>
                  <ShareButtons>
                    <ShareButton $platform="facebook" onClick={() => shareOnFacebook(calculateResult().title)}>Share on Facebook</ShareButton>
                    <ShareButton $platform="twitter" onClick={() => shareOnTwitter(calculateResult().title)}>Share on Twitter</ShareButton>
                    <ShareButton $platform="instagram" onClick={shareOnInstagram}>Share on Instagram</ShareButton>
                  </ShareButtons>
                </>
              )}
            </ResultsContainer>
          </TransitionWrapper>
        )}
      </TransitionGroup>

      {showModal && (
        <SignupModal
          onClose={() => setShowModal(false)}
          quizId={quizId || ''}
          customText="Sign up to join the waitlist and get your meal prep persona!"
        />
      )}
    </QuizContainer>
  );
};

export default MealPlanningQuiz;

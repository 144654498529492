import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { collection, addDoc, getFirestore } from 'firebase/firestore';

// Styled Components
const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 400px;
  width: 90%;
  text-align: center;

  @media (max-width: 600px) {
    padding: 20px;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const StyledButton = styled.button<{ $primary?: boolean }>`
  background-color: ${({ $primary }) => ($primary ? '#ff6f61' : '#ccc')};
  color: ${({ $primary }) => ($primary ? '#fff' : '#333')};
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: ${({ $primary }) => ($primary ? '#ff4b3a' : '#b3b3b3')};
    outline: none;
  }

  &:focus {
    outline: 2px solid #ff6f61;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between input fields */
  margin-bottom: 20px; /* Space between input and button */
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 0;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;

  &:focus {
    outline: 2px solid #ff6f61;
    border-color: #ff6f61;
  }
`;

interface SignupModalProps {
  onClose: () => void; // Close modal callback
  quizId?: string; // Optional quiz ID to associate with the waitlist entry
  customText?: string; // Optional custom text for the modal
}

const SignupModal: React.FC<SignupModalProps> = ({ onClose, quizId, customText }) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!name || !email) {
      toast.error('Please fill in both name and email.');
      return;
    }

    setLoading(true);

    try {
      const db = getFirestore();
      const waitlistRef = collection(db, 'waitlist');

      // Add the user to the waitlist with optional quizId
      await addDoc(waitlistRef, {
        name,
        email,
        quizId: quizId || null,
        timestamp: new Date(),
      });

      toast.success('Successfully signed up for the waitlist!');
      onClose(); // Close the modal after success
    } catch (error) {
      console.error('Error adding to waitlist:', error);
      toast.error('Failed to sign up. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Overlay onClick={onClose} />
      <Modal>
        <h2>{customText || 'Join our waitlist'}</h2>
        <InputContainer>
          <StyledInput
            type="text"
            name="name"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <StyledInput
            type="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </InputContainer>

        <StyledButton onClick={handleSubmit} $primary disabled={loading}>
          {loading ? 'Submitting...' : 'Join the Waitlist'}
        </StyledButton>
      </Modal>
    </>
  );
};

export default SignupModal;

// src/components/Blog/Blog.tsx
import React, { FC, useEffect, useState } from 'react';
import {
  BlogContainer,
  BlogTitle,
  PostsList,
  Loader,
  ErrorMessage,
} from './Blog.styles';
import BlogPost from './BlogPost'; // Ensure BlogPost is correctly imported
import { db } from '../../firebase';
import { collection, getDocs, query, orderBy, QuerySnapshot, DocumentData } from 'firebase/firestore'; // Correct imports
import { toast } from 'react-toastify';

interface BlogPostData {
  id: string;
  title: string;
  author: string;
  date: string;
  excerpt: string;
}

const Blog: FC = () => {
  const [posts, setPosts] = useState<BlogPostData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const postsCollection = collection(db, 'blogPosts');
        const q = query(postsCollection, orderBy('date', 'desc'));
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(q);
        const fetchedPosts: BlogPostData[] = [];
        querySnapshot.forEach((docSnap) => { // Typed parameter
          const data = docSnap.data();
          fetchedPosts.push({
            id: docSnap.id,
            title: data.title,
            author: data.author,
            date: data.date.toDate().toISOString(),
            excerpt: data.excerpt,
          });
        });
        setPosts(fetchedPosts);
      } catch (err) {
        console.error(err);
        setError('Failed to load blog posts. Please try again later.');
        toast.error('Failed to load blog posts. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <BlogContainer>
        <Loader>Loading blog posts...</Loader>
      </BlogContainer>
    );
  }

  if (error) {
    return (
      <BlogContainer>
        <ErrorMessage>{error}</ErrorMessage>
      </BlogContainer>
    );
  }

  return (
    <BlogContainer>
      <BlogTitle>Our Blog</BlogTitle>
      {posts.length === 0 ? (
        <p>No blog posts available at the moment.</p>
      ) : (
        <PostsList>
          {posts.map((post) => (
            <BlogPost
              key={post.id}
              id={post.id}
              title={post.title}
              author={post.author}
              date={post.date}
              excerpt={post.excerpt}
            />
          ))}
        </PostsList>
      )}
    </BlogContainer>
  );
};

export default Blog;

// src/components/FAQ.tsx
import React, { FC } from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 80px 20px;
  background-color: #f9f9f9;
`;

const Headline = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
`;

const FAQItem = styled.div`
  max-width: 800px;
  margin: 0 auto 1.5rem auto;
  text-align: left;
`;

const Question = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const Answer = styled.p`
  font-size: 1rem;
  color: #555;
`;

const FAQ: FC = () => {
  return (
    <Section>
      <Headline>Frequently Asked Questions</Headline>
      <FAQItem>
        <Question>What is Sporksy?</Question>
        <Answer>
          Sporksy is an AI-powered meal planning service that takes the guesswork out of planning, shopping, and cooking. We help you reclaim time by automating the parts of meal preparation that usually take hours—like creating meal plans and grocery lists.
        </Answer>
      </FAQItem>
      <FAQItem>
        <Question>How does Sporksy personalize my meal plans?</Question>
        <Answer>
          We use AI to learn your family’s tastes, dietary preferences, and nutritional needs. You tell us what you like (and don’t), and we’ll tailor meal recommendations just for you.
        </Answer>
      </FAQItem>
      <FAQItem>
        <Question>Can Sporksy order groceries for me?</Question>
        <Answer>
          Yes! We can send your shopping list to your preferred grocery delivery service, so your groceries arrive at your door. All you need to do is unpack and cook.
        </Answer>
      </FAQItem>
      <FAQItem>
        <Question>How does Sporksy help reduce food waste?</Question>
        <Answer>
          By portioning meals accurately and planning around what’s already in your kitchen, Sporksy helps you use up ingredients before they go to waste, saving you money and reducing environmental impact.
        </Answer>
      </FAQItem>
      <FAQItem>
        <Question>What does the demo include?</Question>
        <Answer>
          The demo gives you a sneak peek at how Sporksy personalizes meal plans, generates shopping lists, and integrates with grocery delivery services. You’ll get a sense of how much time and effort Sporksy can save you!
        </Answer>
      </FAQItem>
      <FAQItem>
        <Question>Is there a cost for using Sporksy?</Question>
        <Answer>
          We offer a variety of plans based on your needs. The basic version is free, but we have premium options for more advanced features, such as automatic grocery delivery integration and pre-prepped ingredient kits.
        </Answer>
      </FAQItem>
      <FAQItem>
        <Question>Can I cancel at any time?</Question>
        <Answer>
          Absolutely. You’re in control, and if you decide Sporksy isn’t the right fit for you, you can cancel anytime with no fees.
        </Answer>
      </FAQItem>
      <FAQItem>
        <Question>How do I join the waitlist and get access to the demo?</Question>
        <Answer>
          It’s easy! Simply sign up with your email, and we’ll send you an access code once your group is selected for the trial. You’ll be one of the first to experience Sporksy in action.
        </Answer>
      </FAQItem>
    </Section>
  );
};

export default FAQ;

import React, { useState } from 'react';
import {
  Nav,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  Hamburger,
  Overlay
} from './Header.styles';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { currentUser } = useAuth();  // Use currentUser to determine login status

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Nav aria-label="Main Navigation">
        <Logo as={Link} to="/" onClick={closeMenu}>
          Sporksy
        </Logo>
        <Hamburger
          onClick={toggleMenu}
          $isOpen={isOpen}
          aria-label={isOpen ? 'Close menu' : 'Open menu'}
          aria-expanded={isOpen}
        >
          <div />
          <div />
          <div />
        </Hamburger>
        <Overlay $isOpen={isOpen} onClick={closeMenu} aria-hidden={!isOpen} />
        <Menu $isOpen={isOpen} role="menu">
          <MenuItem role="none">
          <MenuLink as={Link} to="/" role="menuitem" onClick={closeMenu}>
            Home
            </MenuLink>
          </MenuItem>
          <MenuItem role="none">
            <MenuLink as={Link} to="/Blog" role="menuitem" onClick={closeMenu}>
              Blog
            </MenuLink>
          </MenuItem>

          {/* Conditionally render Account link if the user is logged in */}
          {/*{currentUser ? (
            <MenuItem role="none">
              <MenuLink as={Link} to="/Account" role="menuitem" onClick={closeMenu}>
                Account
              </MenuLink>
            </MenuItem>
          ) : (
            <MenuItem role="none">
              <MenuLink as={Link} to="/Login" role="menuitem" onClick={closeMenu}>
                Login
              </MenuLink>
            </MenuItem> 
          )}*/}
        </Menu>
      </Nav>
    </>
  );
};

export default Header;

// src/components/Footer.tsx
import React, { FC } from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 40px 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
`;

const Links = styled.div`
  margin-bottom: 1rem;

  a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialLinks = styled.div`
  margin-bottom: 1rem;

  a {
    color: #fff;
    margin: 0 5px;
    font-size: 1.2rem;

    &:hover {
      color: #ff6f61;
    }
  }
`;

const Footer: FC = () => {
  return (
    <FooterContainer>
      <Links>
        <a href="/contact">Contact Us</a> | <a href="/terms">Terms and Conditions</a> | <a href="/privacy">Privacy Policy</a>
      </Links>
      <SocialLinks>
        <a href="https://facebook.com/sporksy" aria-label="Facebook">
          <FaFacebookF />
        </a>
        <a href="https://twitter.com/sporksy" aria-label="Twitter">
          <FaTwitter />
        </a>
        <a href="https://instagram.com/sporksy" aria-label="Instagram">
          <FaInstagram />
        </a>
      </SocialLinks>
      <p>&copy; {new Date().getFullYear()} Sporksy. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;

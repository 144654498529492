import React from 'react';
import styled from 'styled-components';

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-width: 250px; /* Add a minimum width */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;


const OptionLabel = styled.label<{ $hasError: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: ${({ $hasError }) => ($hasError ? '1px solid red' : 'none')};
  padding: 5px;
  border-radius: 4px;

  &:focus-within {
    outline: 2px solid #ff6f61;
  }

  input {
    margin-right: 10px;
    transform: scale(1.2);
  }
`;

interface RadioGroupProps {
  name: string;
  options: { id: string; label: string; value: string | number }[];
  selectedValue: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ariaDescribedBy?: string;
}

const RadioGroup: React.FC<RadioGroupProps> = React.memo(
  ({ name, options, selectedValue, onChange, ariaDescribedBy }) => (
    <OptionsContainer role="radiogroup" aria-describedby={ariaDescribedBy}>
      {options.map((option) => (
        <OptionLabel key={option.id} htmlFor={option.id} $hasError={!!ariaDescribedBy}>
          <input
            type="radio"
            id={option.id}
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
            aria-checked={selectedValue === option.value}
          />
          {option.label}
        </OptionLabel>
      ))}
    </OptionsContainer>
  )
);

export default RadioGroup;

// src/hooks/useAuth.ts
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import { auth } from '../firebase';
import { onAuthStateChanged, getIdTokenResult } from 'firebase/auth';

interface AuthContextType {
  currentUser: any;
  isAdmin: boolean;
}

export const useAuth = (): AuthContextType => {
  const { currentUser } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const checkAdmin = async () => {
      if (currentUser) {
        const token = await getIdTokenResult(currentUser);
        setIsAdmin(!!token.claims.admin);
      } else {
        setIsAdmin(false);
      }
    };
    checkAdmin();
  }, [currentUser]);

  return { currentUser, isAdmin };
};

// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithPopup,
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  createUserWithEmailAndPassword,
  linkWithCredential,
  EmailAuthProvider,
  onAuthStateChanged,
  signInAnonymously,
  signInWithRedirect,
  getRedirectResult
} from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  addDoc, 
  updateDoc, 
  doc, 
  serverTimestamp,
  deleteDoc,
  getDocs
} from 'firebase/firestore';
import { 
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from 'firebase/storage';
import firebaseConfig from './firebaseconfig';

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Initialize Firebase Services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize storage
const googleProvider = new GoogleAuthProvider();

// Export Firebase Auth Providers and Methods
export { 
  auth, 
  db, 
  storage, // Export storage
  GoogleAuthProvider, 
  googleProvider,
  signInWithPopup, 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword, 
  linkWithCredential, 
  EmailAuthProvider,
  onAuthStateChanged,
  signInAnonymously,
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  getDocs,
  storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  signInWithRedirect,
  getRedirectResult
}; 

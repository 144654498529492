// client/src/components/landingpage/CTAButton.tsx
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

// Extend the button attributes to include all standard button props
interface CTAButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  // Add any additional props if necessary
}

const StyledCTAButton = styled.button`
  padding: 15px 20px;
  background-color: #ff6f61;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e65b50;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const CTAButton: FC<CTAButtonProps> = ({ children, ...props }) => {
  return <StyledCTAButton {...props}>{children}</StyledCTAButton>;
};

export default CTAButton;

import React from 'react';
import styled from 'styled-components';

const StyledTextInput = styled.input<{ $hasError: boolean }>`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid ${({ $hasError }) => ($hasError ? 'red' : '#ccc')};

  &:focus {
    outline: 2px solid #ff6f61;
    border-color: #ff6f61;
  }
`;

interface TextInputFieldProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean;
  ariaDescribedBy?: string;
}

const TextInputField: React.FC<TextInputFieldProps> = React.memo(
  ({ name, value, onChange, placeholder, required = false, ariaDescribedBy }) => (
    <StyledTextInput
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      aria-required={required}
      aria-describedby={ariaDescribedBy}
      $hasError={!!ariaDescribedBy}
    />
  )
);

export default TextInputField;
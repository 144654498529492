// src/components/AboutUs.tsx
import React, { FC } from 'react';
import styled from 'styled-components';
import CTAButton from './CTAButton';

interface AboutUsProps {
  onJoinWaitlist: () => void;
}

const Section = styled.section`
  padding: 80px 20px;
  background-color: #f9f9f9;
  text-align: center;
`;

const Headline = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Subheadline = styled.p`
  font-size: 1.1rem;
  margin-bottom: 2rem;
`;

const BodyText = styled.p`
  font-size: 1rem;
  max-width: 800px;
  margin: 0 auto 2rem auto;
  line-height: 1.6;
`;

const AboutUs: FC<AboutUsProps> = ({ onJoinWaitlist }) => {
  return (
    <Section>
      <Headline>Why We Built Sporksy</Headline>
      <Subheadline>
        We’re two regular guys—one a busy professional and the other a working family man—who needed a solution to simplify meal planning and grocery shopping for ourselves, our friends, and our families.
      </Subheadline>
      <BodyText>
        We’re just like you—constantly juggling the demands of life, work, and family. Between balancing our careers and making time for the people we love, the last thing we wanted was to spend hours stressing over meals. 
      </BodyText>
      <BodyText>
      That's why we built Sporksy—to automate the tedious parts of meal planning and grocery shopping, so you can spend more time enjoying meals, not planning them.
      </BodyText>
      <BodyText>
        Whether you're a parent looking for quick, healthy dinner ideas, or a busy professional who needs meals ready with minimal effort, Sporksy is designed for you. Our mission is to create a world where everyone can eat well without the hassle, where meals are fun and stress-free, and where sustainability is just part of the process.
      </BodyText>
      <CTAButton onClick={onJoinWaitlist}>Limited spots available for early adopters</CTAButton>
    </Section>
  );
};

export default AboutUs;

// client/src/components/landingpage/LandingPage.tsx
import React, { FC, useState } from 'react';
import HeroSection from './HeroSection';
import HowItWorks from './HowItWorks';
import DemoSection from './DemoSection';
import AboutUs from './AboutUs';
import Features from './Features';
import FAQ from './FAQ';
import Testimonials from './Testimonials'; // Optional
import Footer from './Footer';
import Survey from './Survey'; // Updated import
import SignupModal from './SignupModal'; // Import the SignupModal
import MealPlanningQuiz from '../quiz/MealPlanningQuiz'; // Import the Quiz

interface LandingPageProps {
  // No props needed as we're managing internally
}

const LandingPage: FC<LandingPageProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  // Array of image paths for the slideshow
  const slideshowImages = [
    '/assets/pink-avocado.png',
  /*  '/assets/image2.png',
    '/assets/image3.png',
    '/assets/image4.png',
    '/assets/image5.png',
    '/assets/image6.png',
    '/assets/image7.png',
    '/assets/image8.png',
    '/assets/image9.png',
    '/assets/image10.png', */
  ];

  // Handler to open the SignupModal
  const handleJoinWaitlist = () => {
    setIsModalOpen(true);
  };

  // Handler to close the SignupModal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <HeroSection images={slideshowImages} onJoinWaitlist={handleJoinWaitlist} />
      <HowItWorks onJoinWaitlist={handleJoinWaitlist} />
      <MealPlanningQuiz onJoinWaitlist={handleJoinWaitlist} /> {/* Add the Quiz here */}
      <DemoSection onJoinWaitlist={handleJoinWaitlist} />
      <AboutUs onJoinWaitlist={handleJoinWaitlist} />
      <Features onJoinWaitlist={handleJoinWaitlist} />
      <FAQ />
      <Survey />
      {Testimonials && <Testimonials />} {/* Pass the handler if Testimonials has CTA buttons */}
      <Footer /> {/* Ensure Footer's CTA uses the handler */}

      {/* Conditionally render the SignupModal */}
      {isModalOpen && (
        <SignupModal 
          onClose={handleCloseModal} 
          customText="Join our waitlist by entering your name and email" 
        />
      )}
    </>
  );
};

export default LandingPage;

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { SurveyResponses } from '../../types';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import ProgressBar from './ProgressBar';
import SurveyStep from './SurveyStep';
import { SurveyContainer } from '../../styles/SurveyContainer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define mandatory questions per step
const mandatoryQuestions: { [key: number]: Array<keyof SurveyResponses> } = {
  0: ['mealPlanning', 'groceryShopping'],
  1: ['mealPlanningChallenges', 'mealPlanningStress'],
  2: ['currentGrocerySpending', 'willingToPay'],
  3: ['valuableFeatures'],
  4: ['sustainabilityImportance'],
  5: ['name', 'email'], // Added mandatory fields for name and email in the final step
};

// Define total steps for the survey
const totalSteps = 6;

const SurveyTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
`;

const SurveyDescription = styled.p`
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #555;
  text-align: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const StyledButton = styled.button<{ $primary?: boolean }>`
  background-color: ${({ $primary }) => ($primary ? '#ff6f61' : '#ccc')};
  color: ${({ $primary }) => ($primary ? '#fff' : '#333')};
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin: 10px 10px;
  min-width: 120px;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: ${({ $primary }) => ($primary ? '#ff4b3a' : '#b3b3b3')};
    outline: none;
  }

  &:focus {
    outline: 2px solid #ff6f61;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 10px 0;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
`;

const Survey: FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [responses, setResponses] = useState<SurveyResponses>({
    mealPlanning: '',
    groceryShopping: '',
    mealPlanningTime: '',
    mealInspiration: '',
    mealPlanningChallenges: [],
    mealPlanningStress: 0,
    foodWasteFrequency: '',
    interestInService: '',
    currentGrocerySpending: '',
    willingToPay: '',
    valuableFeatures: [],
    sustainabilityImportance: '',
    mealPredictionAppeal: '',
    autoIngredientDelivery: '',
    easeOfMealPlanning: '',
    changeMealPlanning: '',
    receiveUpdates: '',
    additionalFeedback: '',
    desiredFeatures: '',
    name: '', // New field for name
    email: '', // New field for email
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // Handle form field changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target;
    const name = target.name as keyof SurveyResponses;
    const value = target.value;

    if (target instanceof HTMLInputElement && target.type === 'checkbox') {
      const checked = target.checked;
      const currentValues = responses[name] as string[];

      if (checked) {
        setResponses({
          ...responses,
          [name]: [...currentValues, value],
        });
      } else {
        setResponses({
          ...responses,
          [name]: currentValues.filter((item) => item !== value),
        });
      }
    } else if (target.type === 'radio') {
      if (name === 'mealPlanningStress') {
        setResponses({
          ...responses,
          [name]: parseInt(value, 10),
        });
      } else {
        setResponses({
          ...responses,
          [name]: value,
        });
      }
    } else {
      setResponses({
        ...responses,
        [name]: value,
      });
    }

    // Clear error for the field if user interacts
    if (mandatoryQuestions[currentStep]?.includes(name)) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  // Validate the current step
  const validateStep = (): boolean => {
    const requiredFields = mandatoryQuestions[currentStep] || [];
    const newErrors: { [key: string]: string } = {};

    requiredFields.forEach((field: keyof SurveyResponses) => {
      const value = responses[field];
      if (
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'number' && isNaN(value))
      ) {
        newErrors[field] = 'This field is required.';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle moving to the next step
  const handleNext = () => {
    if (validateStep()) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  // Handle moving to the previous step
  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  // Handle survey submission
  const handleSurveySubmit = async () => {
    if (!validateStep()) return;

    setIsSubmitting(true);
    try {
      console.log('Submitting survey with responses:', responses);

      // Save the survey data to Firebase
      await addDoc(collection(db, 'surveys'), responses);
      toast.success('Survey submitted successfully!', {
        position: 'top-center',
        autoClose: 5000,
      });
    } catch (error) {
      console.error('Error submitting survey:', error);
      toast.error('Failed to submit survey. Please try again later.', {
        position: 'top-center',
        autoClose: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const progressPercentage = ((currentStep + 1) / totalSteps) * 100;

  return (
    <SurveyContainer>
      <SurveyTitle>We'd Love Your Feedback!</SurveyTitle>
      <SurveyDescription>Help us improve our meal planning services by completing this survey.</SurveyDescription>

      {/* Progress Bar */}
      <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />

      {/* Render Survey Steps */}
      <SurveyStep
        step={currentStep}
        responses={responses}
        handleChange={handleChange}
        errors={errors}
      />

      {/* Navigation Buttons */}
      <StyledButtonContainer>
        {currentStep > 0 && (
          <StyledButton onClick={handlePrevious} aria-label="Previous Step">
            Previous
          </StyledButton>
        )}
        {currentStep < totalSteps - 1 ? (
          <StyledButton $primary onClick={handleNext} aria-label="Next Step">
            Next
          </StyledButton>
        ) : (
          <StyledButton
            $primary
            onClick={handleSurveySubmit}
            disabled={isSubmitting}
            aria-label="Submit Survey"
          >
            {isSubmitting ? 'Submitting...' : 'Submit Survey'}
          </StyledButton>
        )}
      </StyledButtonContainer>
    </SurveyContainer>
  );
};

export default Survey;
